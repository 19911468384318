<template>
  <div class="invoice-container">
    <div class="cm-btn-align-right" v-if="Object.keys(invoiceList).length">
            <Button
              class="edit-btn"
              @click="toggleEditSection()"
              :label="$t(`Edit Details`)" :color="'primary'"
              v-if="!editSection"
            />
            <Button
              class="cancel-btn"
              @click="toggleEditSection()"
              :type="'outlined'"
              :label="$t(`cancel`)" :color="'primary'"
              v-else
            />
    </div>
    <v-card class="invoice-table" v-if="Object.keys(invoiceList).length">
          <table class="table">
            <thead>
              <tr>
                <th class="table-header">Invoice Range</th>
                <th class="table-header">Invoice Amount</th>
                <th class="table-header">Penalties Amount</th>
                <th class="table-header">Deduction</th>
                <th class="table-header">Deduction Reason</th>
                <th class="table-header">Payment Amount (After penalty)</th>
                <th class="table-header">Date of Physical Verification</th>
                <th class="table-header">Invoice Due Date</th>
                <th class="table-header">Invoice Submitted On</th>
                <th class="table-header">Date of Payment</th>
                <th class="table-header">Invoice Document</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(invoice) in Object.values(invoiceList).filter(i => !i.interim)" :key="invoice.id" >
                <td class="table-data">
                  {{ getInvoiceRange(invoice.invoicingPeriodStartDate, invoice.invoicingPeriodEndDate) }}
                </td>

                <td class="table-data"> {{ invoice.amount }} </td>

                <td class="table-data"> {{ showHyphenIfNull(invoice.penalties) }} </td>

                <td v-if = editSection class="table-data">
                  <input type="number" placeholder="Deduction" v-model="invoicePayments[invoice.id].deduction" class="input-fields" :disabled="existingInvoicePayments[invoice.id].deduction != null">
                </td>
                <td v-else class="table-data"> {{ showHyphenIfNull(invoice.deduction) }} </td>

                <td v-if = editSection class="table-data">
                  <input type="text" placeholder="Deduction Reason" v-model="invoicePayments[invoice.id].deductionReason" class="input-fields" :disabled="existingInvoicePayments[invoice.id].deductionReason != null">
                </td>
                <td v-else class="table-data"> {{ showHyphenIfNull(invoice.deductionReason) }}</td>

                <td class="table-data"> {{ getPaymentAmount(invoice) }} </td>

                <td class="table-data">
                  {{ getDate(invoice.verificationDate) }}
                </td>

                <td v-if = editSection class="table-data">
                  <DatePicker
                    class="date-picker"
                    v-model="invoicePayments[invoice.id].invoiceDueDate"
                    format="dd-MMM-yyyy"
                    placeholder='Bill Due Date'
                    :disabled="existingInvoicePayments[invoice.id].invoiceDueDate != null"
                  />
                </td>
                <td v-else class="table-data">
                  {{ getDate(invoice.invoiceDueDate) }}
                </td>

                <td v-if = editSection class="table-data">
                  <DatePicker
                    class="date-picker"
                    v-model="invoicePayments[invoice.id].invoiceSubmissionDate"
                    format="dd-MMM-yyyy"
                    placeholder='Bill Submission Date'
                    :disabled="existingInvoicePayments[invoice.id].invoiceSubmissionDate != null"
                  />
                </td>
                <td v-else class="table-data">
                {{ getDate(invoice.invoiceSubmissionDate) }}
                </td>

                <td v-if ='editSection' class="table-data">
                  <DatePicker
                    class="date-picker"
                    v-model="invoicePayments[invoice.id].paymentDate"
                    format="dd-MMM-yyyy"
                    placeholder='Payment Date'
                    :disabled="existingInvoicePayments[invoice.id].paymentDate != null"
                  />
                </td>
                <td v-else class="table-data">
                  {{ getDate(invoice.paymentDate) }}
                </td>

                <td class="table-data">
                  <a class="image-url" @click="() => openDocInNewTab(invoice.blobUrl)"> Download Invoice </a>
                </td>
              </tr>
            </tbody>
          </table>
    </v-card>
    <div class = "save-btn" v-if="Object.keys(invoiceList).length" >
      <Button class="btn btn-primary cntrct-btn"
            v-if="editSection"
            :label="$t('_save')"
            :color="'primary'"
            @click="handleInvoiceSaveClick()"/>
     </div>
      <v-card class="no-content card" v-if="Object.keys(invoiceList).length === 0">
        No invoices
      </v-card>
    </div>
</template>

<script>
import Button from '../../../../shared/components/Button.vue'
import DatePicker from '../../../../shared/components/DatePicker.vue'
import { getFormattedDate, showHyphenIfNull, openDocInNewTab } from '../../../../../utils/utils'
import { mapActions } from 'vuex'

export default {
  name: 'InvoiceTab',
  data: () => ({
    editSection: false,
    invoiceList: [],
    existingInvoicePayments: [],
    invoicePayments: []
  }),
  components: {
    Button,
    DatePicker
  },
  async mounted () {
    this.id = this.$route.params.id
    const data = await this.fetchInvoicesList({ reportingUnitId: this.id })
    this.invoiceList = Object.assign({}, ...(data.invoiceDtos.map(item => ({ [item.id]: item }))))
    if (Object.keys(this.invoiceList).length) {
      data.invoiceDtos.forEach(val => {
        const invoicePayments = { ...val, invoiceDueDate: getFormattedDate(val.invoiceDueDate), invoiceSubmissionDate: getFormattedDate(val.invoiceSubmissionDate), verificationDate: getFormattedDate(val.verificationDate), paymentDate: getFormattedDate(val.paymentDate) }
        this.invoicePayments[val.id] = { ...invoicePayments }
        this.existingInvoicePayments[val.id] = { ...invoicePayments }
      })
    }
  },
  methods: {
    showHyphenIfNull,
    openDocInNewTab,
    toggleEditSection () {
      this.editSection = !this.editSection
    },
    async handleInvoiceSaveClick () {
      const modifiedInvoicePayments = []
      var self = this
      Object.values(this.existingInvoicePayments).forEach(i => {
        const invoicePayments = self.invoicePayments[i.id]
        if (JSON.stringify(i) !== JSON.stringify(invoicePayments)) {
          const modifiedValues = {
            ...invoicePayments,
            invoiceId: invoicePayments.id,
            invoiceDueDate: getFormattedDate(invoicePayments.invoiceDueDate),
            invoiceSubmissionDate: getFormattedDate(invoicePayments.invoiceSubmissionDate),
            paymentDate: getFormattedDate(invoicePayments.paymentDate)
          }
          modifiedInvoicePayments.push(modifiedValues)
        }
      })
      if (modifiedInvoicePayments.length) {
        await this.updateInvoicePayments({ payload: { invoicePaymentUpdateRequestList: modifiedInvoicePayments } })
      }
      await this.toggleEditSection()
    },
    getDate (dateTime) {
      let formattedDate = '-'
      if (dateTime != null) {
        formattedDate = dateTime.substring(0, dateTime.indexOf(' '))
      }
      return formattedDate
    },
    getInvoiceRange (startDateTime, endDateTime) {
      const startDate = this.getDate(startDateTime)
      const endDate = this.getDate(endDateTime)
      return `${startDate} to ${endDate}`
    },
    getPaymentAmount (invoice) {
      return invoice.amount != null ? invoice.amount - this.invoicePayments[invoice.id].deduction - invoice.penalties : '-'
    },
    ...mapActions('Invoices', ['fetchInvoicesList', 'updateInvoicePayments'])
  }
}
</script>

<style lang="scss">
  .no-content {
    min-height: 40px;
    margin: 15px 0px 12px 0px;
    justify-content: center;
    padding: 16px;
  }
  .invoice-table {
    min-height: 40px;
    margin: 15px 0px 12px 0px;
    padding: 16px;
  }
  .table-header {
    padding: 8px 16px;
    font-weight: 600 !important;
    border-bottom: thin solid rgba(0,0,0,.12);
    border-width: 2px !important;
    width: calc(100% / 11) !important;
    vertical-align: middle !important;
    height: 47px;
  }
  .table-data{
    padding: 8px;
    border-spacing: 0;
    border-bottom: thin solid rgba(0,0,0,.12);
    border-width: 2px !important;
    width: calc(100% / 11) !important;
    vertical-align: middle !important;
    height: 47px;
  }
  .table-header {
    background-color: var(--light-hover-color-light-dark);
  }
  .tr {
    border-spacing: 0px;
  }
  .image-url {
    cursor: pointer;
  }
  .date-picker {
    display: inline-block;
  }
  .date-picker > div  > input, .input-fields{
    border: 2px;
    text-align: center;
    border-radius: 4px;
    width: 100%;
  }
</style>
